//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex';
import constant from '~/const';

export default {
  props: {
    isLoading: { type: Boolean, default: false },
  },

  data() {
    return {
      filters: {
        appName: '',
        typeList: [],
        dateCreatedFrom: '',
        dateCreatedTo: '',
        enabled: null,
      },
      constant,
    };
  },

  computed: {
    ...mapState('userApiHistory', ['filterLists']),

    enabledList() {
      return [
        { id: true, caption: 'Enabled' },
        { id: false, caption: 'Disabled' },
      ];
    },
  },

  watch: {
    filters: {
      async handler(data) {
        await this.setFilters(data);
        this.$emit('update');
      },
      deep: true,
    },
  },

  created() {
    this.setFilters({});
  },

  methods: {
    ...mapActions('userApiHistory', ['setFilters']),
  },
};
